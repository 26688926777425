import React, { useState, useEffect } from "react";
import { Table, Input, Button, Modal, Select, message,Row,Col } from "antd";
import axios from "axios"; // Using axios for API requests
import api from "../../commonFuncs/api";
import CustomPopupConfirm from "./CustomPopupConfirm";

const { Option } = Select;

const AdminPanel = () => {
  const [jobTypes, setJobTypes] = useState([]);
  const [newJobType, setNewJobType] = useState("");
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedFields, setSelectedFields] = useState([]);
  const [fieldTypes, setFieldTypes] = useState({});
  const [isPopup, setPopup] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newFieldName, setNewFieldName] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [newFieldType, setNewFieldType] = useState("text");
  const token = localStorage.getItem("token");

  // Fetch all job profiles on mount
  useEffect(() => {
    fetchJobProfiles();
  }, []);

  const fetchJobProfiles = async () => {
    try {
      const response = await api.get(
        "/api/v1/treeDriveAdmin/jobProfiles/getAllProfiles"
      );
      setJobTypes(response.data.data || []);
    } catch (error) {
      message.error("Failed to fetch job types.");
      console.error(error);
    }
  };

  const addJobType = async () => {
    if (!newJobType.trim()) return;

    try {
      const jobData = { name: newJobType, fields: [], fieldTypes: {} };
      await api.post(
        "/api/v1/treeDriveAdmin/jobProfiles/addProfiles",
        jobData,
         {
          headers: {
            // "Content-Type": "application/json",
            "x-auth-token": token,
          },
        },
      );
      message.success("Job Type added successfully!");
      setNewJobType("");
      fetchJobProfiles();
    } catch (error) {
      message.error("Error adding job type.");
      console.error(error);
    }
  };

  const openModal = (job) => {
    setSelectedJob(job);
    setSelectedFields(job.fields || []);
    setFieldTypes(job.fieldTypes || {});
    setIsModalVisible(true);
  };

  const addCustomField = () => {
    if (!newFieldName.trim()) return;

    const fieldKey = newFieldName.toLowerCase().replace(/\s+/g, "");
    setSelectedFields([...selectedFields, fieldKey]);
    setFieldTypes({ ...fieldTypes, [fieldKey]: newFieldType });

    setNewFieldName("");
    setNewFieldType("text");
  };

  const saveFields = async () => {
    if (!selectedJob) return;

    const jobData = {
      id: selectedJob._id,
      name: selectedJob.name,
      fields: selectedFields,
      fieldTypes,
    };

    try {
      await api.post("/api/v1/treeDriveAdmin/jobProfiles/addProfiles",
        jobData,{
          headers: {
            // "Content-Type": "application/json",
            "x-auth-token": token,
          },
        },
      );
      message.success("Job fields updated successfully!");
      setIsModalVisible(false);
      fetchJobProfiles();
    } catch (error) {
      message.error("Error saving fields.");
      console.error(error);
    }
  };

  const deleteJobType = async (id) => {
    try {
      await api.post(
        `/api/v1/treeDriveAdmin/jobProfiles/deleteProfiles`,
        {
          id:id
        } , {
          headers: {
            //"Content-Type": "application/json",
            "x-auth-token": token,
          },
        },
      );
      message.success("Job type deleted!");
      fetchJobProfiles();
    } catch (error) {
      message.error("Error deleting job type.");
      console.error(error);
    }
  };
  const showConformationDialog = (id) => {
    setDeleteId(id);
    setPopup(true);
  };
  const cancelConformationDialog = () => {
    setPopup(false);
  };

  const columns = [
    { title: "Job Type", dataIndex: "name", key: "name" },
    {
      title: "Selected Fields",
      dataIndex: "fields",
      key: "fields",
      render: (fields, record) =>
        fields?.map((field) => `${field} (${record.fieldTypes[field] || "text"})`).join(", "),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button onClick={() => openModal(record)} style={{ marginRight: 10 }}>
            Edit 
          </Button>
          <Button type="danger" 
          // onClick={() => deleteJobType(record._id)}
          onClick={() => showConformationDialog(record._id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <h2>Admin Panel - Manage Job Types</h2>
      <Row className="pt-4 pb-4" style={{width:'100%'}}>
      <Col span={5}>
      <Input
        placeholder="Enter Job Type"
        value={newJobType}
        onChange={(e) => setNewJobType(e.target.value)}
        style={{ width: "200px", marginRight: "10px" }}
      />
      </Col>
      <Col>
      <Button type="primary" onClick={addJobType}>
        Add Job Type
      </Button>
      </Col>
      </Row>

      <Table dataSource={jobTypes} columns={columns} style={{ marginTop: 20 }} rowKey="_id" />

      {/* Modal for editing fields */}
      <Modal
        title="Edit Job Fields"
  visible={isModalVisible}
  onOk={saveFields}
  onCancel={() => setIsModalVisible(false)}
  footer={[
    <Button type="primary" onClick={saveFields} key="ok">
      Save Details
    </Button>,
  ]} 
      >
        <h4>Selected Fields</h4>
        <ul>
          {selectedFields.map((field) => (
            <li key={field} style={{ marginBottom: 10 }}>
              {field} -{" "}
              <Select
                style={{ width: 120, marginRight: 10 }}
                value={fieldTypes[field] || "text"}
                onChange={(value) => setFieldTypes({ ...fieldTypes, [field]: value })}
              >
                <Option value="text">Text</Option>
                <Option value="number">Number</Option>
                <Option value="password">Password</Option>
                <Option value="date">Date</Option>
                <Option value="time">Time</Option>
                <Option value="email">Email</Option>
                <Option value="fileImage">Upload Image</Option>
                <Option value="filePdf">Upload PDF</Option>
              </Select>
              {/* <Button
                danger
                onClick={() =>
                  setSelectedFields(selectedFields.filter((f) => f !== field))
                }
              >
                Remove
              </Button> */}
              <Button
  danger
  onClick={() => {
    // Remove field from selectedFields
    const updatedFields = selectedFields.filter((f) => f !== field);
    
    // Remove field from fieldTypes
    const updatedFieldTypes = { ...fieldTypes };
    delete updatedFieldTypes[field];

    // Update states
    setSelectedFields(updatedFields);
    setFieldTypes(updatedFieldTypes);
  }}
>
  Remove
</Button>

            </li>
          ))}
        </ul>

        {/* Add custom field section */}
        <div style={{ marginTop: 20 }}>
          <h4>Add Custom Field</h4>
          <Input
            placeholder="Field Name"
            value={newFieldName}
            onChange={(e) => setNewFieldName(e.target.value)}
            style={{ width: "150px", marginRight: "10px" }}
          />
          <Select
            value={newFieldType}
            onChange={setNewFieldType}
            style={{ width: "120px", marginRight: "10px" }}
          >
            <Option value="text">Text</Option>
            <Option value="number">Number</Option>
            <Option value="password">Password</Option>
            <Option value="date">Date</Option>
            <Option value="time">Time</Option>
            <Option value="email">Email</Option>
            <Option value="fileImage">Upload Image</Option>
            <Option value="filePdf">Upload PDF</Option>
          </Select>
          <Button onClick={addCustomField}>Add</Button>
        </div>
      </Modal>
      <CustomPopupConfirm
          visible={isPopup}
          cancel={cancelConformationDialog}
          productId={deleteId}
          action={deleteJobType}
        />
    </div>
  );
};

export default AdminPanel;
