import React, { useEffect, useState } from "react";

import {
  MdRestaurant
} from 'react-icons/md';
import api from "../../../commonFuncs/api";

import { AiOutlineAppstore } from "react-icons/ai";

import {
  FaChartBar,
  FaCoins,
  FaProductHunt,
  FaTruck,
  FaUserTie 
} from 'react-icons/fa';

import {
  HomeOutlined,
  TeamOutlined,
  CarOutlined,
  HddOutlined,
  FileTextOutlined,
  AlignLeftOutlined,
  CopyOutlined,
  UnorderedListOutlined
  //  ProductOutlined
} from '@ant-design/icons';

import { NavLink } from 'react-router-dom';

import { Layout, Menu ,Badge} from 'antd';

const SideNav = () => {
  const [unverifiedDriverCount, setUnverifiedDriverCount] = useState(0);
  const [collapsed, setCollapsed] = useState(false);


  
  useEffect(() => {
    fetchDriversApi();
  }, []);

   // Define the function inside your component
   const updateUnverifiedDriverCount = (newCount) => {
    setUnverifiedDriverCount(newCount);
    localStorage.setItem("userVerifiedCount", newCount);
  };
  const fetchDriversApi = async () => {
    try {
      let result = await api.get(
        `/api/v1/treeDriveAdmin/appdriver/driverfetch`
      );

      // setDriversData(result.data.data.drivers);

            // Count unverified drivers
      const unverifiedCount = result.data.data.drivers.filter(driver => driver.isDriverVerified === false).length;
      // // Save unverified count to localStorage as "userVerifiedCount"
      // localStorage.setItem("userVerifiedCount", unverifiedCount);
      
      // // Optionally, retrieve it immediately from localStorage
      // const storedCount = parseInt(localStorage.getItem("userVerifiedCount"), 10);
      // setUnverifiedDriverCount(storedCount);

      updateUnverifiedDriverCount(unverifiedCount);
    } catch (err) {
      console.log("error--->", err.message);
    }
  };

  console.log("unverifiedCount", unverifiedDriverCount)

  return (
    <>
      <Menu className='side-nav'
      collapsed={collapsed} onCollapse={setCollapsed}
        mode='inline'
        defaultSelectedKeys={['1']}
        items={[
          {
            key: '1',
            icon: <HomeOutlined />,
            label: <NavLink to='/dashboard'>Dashboard</NavLink>,
          },
          {
            key: '2',
            icon: <TeamOutlined />,
            label: <NavLink to='/userlistmain'>Users</NavLink>,
          },
          // {
          //   key: '3',
          //   icon: <CarOutlined />,
          //   label: <NavLink to='/driverlistmain'>Drivers</NavLink>,
          // },
//           {
//   key: '3',
//   icon: (
//     <CarOutlined style={{ color: unverifiedDriverCount > 0 ? "red" : "inherit" }} />
//   ),
//   label: <NavLink to='/driverlistmain'>Drivers</NavLink>,
// },
{
  key: '3',
  icon: <CarOutlined style={{ color: unverifiedDriverCount > 0 ? "red" : "inherit" }} />,
  label: (
    <span style={{ display: "flex", alignItems: "center" }}>
      <NavLink to='/driverlistmain'>Drivers</NavLink>
      {!collapsed && unverifiedDriverCount > 0 && (
        <Badge 
          count={unverifiedDriverCount} 
          offset={[10, 0]} 
          style={{ backgroundColor: "#f5222d", marginLeft: "10px" }} 
        />
      )}
    </span>
  ),
},

          {
            key: '4',
            icon: <MdRestaurant />,
            label: <NavLink to='/restaurantlistmain'>Restaurants</NavLink>,
          },
          // {
          //   key: '5',
          //   icon: <GiModernCity />,
          //   label: <NavLink to='/citylist'>Cities</NavLink>,
          // },
          {
            key: '6',
            icon: <FaChartBar />,
            label: <NavLink to='/oppgjor'>Oppgjør</NavLink>,
          },
          {
            key: '7',
            icon: <HddOutlined />,
            label: <NavLink to='/settings'>Setting</NavLink>,
          },
          {
            key: "8",
            icon: <FaCoins />,
            label: <NavLink to="/points">Coin Redemption</NavLink>
          },
          {
            key: "9",
            icon: <AiOutlineAppstore />,
            label: <span>TFL Market</span>,
            children: [
              {
                key: 10,
                icon: <UnorderedListOutlined style={{ color: "black" }} />,
                label: <NavLink to="/tflOrders" style={{ color: "black" }}>TFL Orders</NavLink>
              },
              {
                key: 11,
                icon: <FaProductHunt style={{ color: "black" }} />,
                label: <NavLink to="/tflPoints" style={{ color: "black" }}> TFL Products</NavLink>
              },
              {
                key: 12,
                icon: <FaTruck style={{ color: "black" }} />,
                label: <NavLink to="/postenCharges" style={{ color: "black" }}>Posten Charges</NavLink>
              },
              {
                key: 13,
                icon: <FileTextOutlined style={{ color: "black" }} />,
                label: <span>TFL Reports</span>,
                children: [
                  {
                    key: 14,
                    icon: <AlignLeftOutlined style={{ color: "black" }} />,
                    label: <NavLink to="/tflReport" style={{ color: "black" }}>Daily Report</NavLink>
                  },
                  // {
                  //   key:15, 
                  //   icon:<CopyOutlined   style={{color:"black"}} />,
                  //   label:<NavLink to = "/tflZReport" style={{color:"black"}}>Z-Report</NavLink>
                  // }

                ],
              }

            ],
          },
          {
            key: 15,
            icon: <FaUserTie  />,
            label: <NavLink to='/jobProfiles'>Profile</NavLink>,
          },
          // {
          //   key:"10",
          //   icon: <FaCity />,
          //   label:<NavLink to = "/citylist">Cities</NavLink>
          // },
          // {
          //   key:"11",
          //   icon: <FaDonate />,
          //   label:<NavLink to = "/donation">Donation</NavLink>
          // }
        ]}
      />
    </>
  );
};
export default SideNav;

// import React, { useEffect, useState } from "react";
// import { MdRestaurant } from 'react-icons/md';
// import api from "../../../commonFuncs/api";
// import { AiOutlineAppstore } from "react-icons/ai";
// import { FaChartBar, FaCoins, FaProductHunt, FaTruck } from 'react-icons/fa';
// import { HomeOutlined, TeamOutlined, CarOutlined, HddOutlined, FileTextOutlined, AlignLeftOutlined, UnorderedListOutlined } from '@ant-design/icons';
// import { NavLink } from 'react-router-dom';
// import { Layout, Menu, Badge } from 'antd';

// const SideNav = () => {
//   const [unverifiedDriverCount, setUnverifiedDriverCount] = useState(0);

//   useEffect(() => {
//     fetchDriversApi();
//   }, []);

//   const fetchDriversApi = async () => {
//     try {
//       let result = await api.get("/api/v1/treeDriveAdmin/appdriver/driverfetch");
      
//       // Count unverified drivers
//       const unverifiedCount = result.data.data.drivers.filter(driver => driver.isDriverVerified === false).length;
//       setUnverifiedDriverCount(unverifiedCount);
//     } catch (err) {
//       console.log("error--->", err.message);
//     }
//   };

//   return (
//     <>
//       <Menu className='side-nav' mode='inline' defaultSelectedKeys={['1']}>
//         <Menu.Item key='1' icon={<HomeOutlined />}>
//           <NavLink to='/dashboard'>Dashboard</NavLink>
//         </Menu.Item>

//         <Menu.Item key='2' icon={<TeamOutlined />}>
//           <NavLink to='/userlistmain'>Users</NavLink>
//         </Menu.Item>
//         <Menu.Item key='3' style={{ display: "flex", alignItems: "center" }}>
//   <Badge count={unverifiedDriverCount} offset={[-20, 0]} style={{ backgroundColor: "#f5222d" }}>
//     <CarOutlined style={{ fontSize: "18px", marginRight: "10px" }} />
//   </Badge>
//   <NavLink to='/driverlistmain' style={{ marginLeft: "8px" }}>Drivers</NavLink>
// </Menu.Item>

//         <Menu.Item key='4' icon={<MdRestaurant />}>
//           <NavLink to='/restaurantlistmain'>Restaurants</NavLink>
//         </Menu.Item>

//         <Menu.Item key='6' icon={<FaChartBar />}>
//           <NavLink to='/oppgjor'>Oppgjør</NavLink>
//         </Menu.Item>

//         <Menu.Item key='7' icon={<HddOutlined />}>
//           <NavLink to='/settings'>Setting</NavLink>
//         </Menu.Item>

//         <Menu.Item key="8" icon={<FaCoins />}>
//           <NavLink to="/points">Coin Redemption</NavLink>
//         </Menu.Item>

//         <Menu.SubMenu key="9" icon={<AiOutlineAppstore />} title="TFL Market">
//           <Menu.Item key="10" icon={<UnorderedListOutlined />}>
//             <NavLink to="/tflOrders">TFL Orders</NavLink>
//           </Menu.Item>
//           <Menu.Item key="11" icon={<FaProductHunt />}>
//             <NavLink to="/tflPoints">TFL Products</NavLink>
//           </Menu.Item>
//           <Menu.Item key="12" icon={<FaTruck />}>
//             <NavLink to="/postenCharges">Posten Charges</NavLink>
//           </Menu.Item>
//           <Menu.SubMenu key="13" icon={<FileTextOutlined />} title="TFL Reports">
//             <Menu.Item key="14" icon={<AlignLeftOutlined />}>
//               <NavLink to="/tflReport">Daily Report</NavLink>
//             </Menu.Item>
//           </Menu.SubMenu>
//         </Menu.SubMenu>
//       </Menu>
//     </>
//   );
// };

// export default SideNav;
