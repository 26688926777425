import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button, message, Form, Switch,Image,
  Avatar, } from "antd";
import api from "../../commonFuncs/api";
import openNotification from "../../commonFuncs/notification";
const s3url = "https://pos-stockholm-bucket.s3.eu-north-1.amazonaws.com";

const propsupload = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text"
  },

  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }

    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }
};

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};

export default function EditDrivers(props) {
  const [blockDriver, setBlockDriver] = useState(
    props.driver != undefined ? props.driver.isBlocked : true
  );
  const [verifiedDriver, setVerifiedDriver] = useState(
    props.driver != undefined ? props.driver.isDriverVerified : false
  );
  const [driverData, setDriverData] = useState(props.driver || {});

  const [inputExtraFee, setInputExtraFee] = useState(
    props.driver && props.driver.driverFee.extraFeePerKm
      ? props.driver.driverFee.extraFeePerKm
      : 0
  );
  const [inputDistanceForFlatRate, setInputDistanceForFlatRate] = useState(
    props.driver && props.driver.driverFee.flatRateDistance
      ? props.driver.driverFee.flatRateDistance
      : 0
  );

  const [errorObj, setErrorObj] = useState({});
  const [formData, setFormData] = useState({});

  const onFinish = (values) => {
    console.log(values);
  };

  useEffect(() => {
    let formValues = {
      firstName: driverData.firstName || "",
      lastName: driverData.lastName || "",
      phoneNumber: driverData.phoneNumber || "",
      email: driverData.email || "",
      licenseNumber: driverData.licenseNumber || "0",
      houseNumber: driverData?.address?.houseNumber || "",
      street: driverData?.address?.street || "",
      pinCode: driverData?.address?.pinCode || "",
      city: driverData?.address?.city || "",
      state: driverData?.address?.state || "",
      country: driverData?.address?.country || "",
      vehicleType: driverData?.vehicle?.vehicleType || "notKnown",
      vehicleRegNumber: driverData?.vehicle?.vehicleRegNumber || "0",
      vehicleColor: driverData?.vehicle?.vehicleColor || "notKnown",
      description: driverData.description || ""
    };

    let errorValues = {
      firstName: false,
      lastName: false,
      phoneNumber: false,
      email: false,
      licenseNumber: false,
      houseNumber: false,
      street: false,
      pinCode: false,
      city: false,
      state: false,
      country: false,
      vehicleType: false,
      vehicleRegNumber: false,
      vehicleColor: false,
      description: false
    };

    if (props.edit) {
      //in formValues driver id is added
      formValues.driverId = driverData._id;
      formValues.driverFeePerDel = driverData?.driverFee?.perDel || "0";
      formValues.range1Min = driverData?.driverFeeRange?.range1Min || "0";
      formValues.range1Max = driverData?.driverFeeRange?.range1Max || "0";
      formValues.perKmFee1 = driverData?.driverFeeRange?.perKmFee1 || "0";
      formValues.range2Min = driverData?.driverFeeRange?.range2Min || "0";
      formValues.range2Max = driverData?.driverFeeRange?.range2Max || "0";
      formValues.perKmFee2 = driverData?.driverFeeRange?.perKmFee2 || "0";
      formValues.range3Min = driverData?.driverFeeRange?.range3Min || "0";
      formValues.range3Max = driverData?.driverFeeRange?.range3Max || "0";
      formValues.perKmFee3 = driverData?.driverFeeRange?.perKmFee3 || "0";
      errorValues.range1Min = false;
      errorValues.range1Max = false;
      errorValues.perKmFee1 = false;
      errorValues.range2Min = false;
      errorValues.range2Max = false;
      errorValues.perKmFee2 = false;
      errorValues.range3Min = false;
      errorValues.range3Max = false;
      errorValues.perKmFee3 = false;
      errorValues.driverFeePerDel = false;
    }
    if (props.add) {
      formValues.password = "";
      errorValues.password = false;
    }

    setErrorObj(errorValues);
    setFormData(formValues);
  }, []);

  let firstLoad = true;

  const onBlockChange = (checked) => {
    setBlockDriver(checked);
    props.onBlockStatusChange(checked, props.driver._id);
  };

  const verifyDriver = (checked) => {
    setVerifiedDriver(checked);
    props.onVerifiedDriver(checked, props.driver._id);


      // Get the current count from localStorage
  let userVerifiedCount = localStorage.getItem("userVerifiedCount");
  userVerifiedCount = userVerifiedCount ? parseInt(userVerifiedCount) : 0;

  // Adjust count based on toggle action
  if (checked) {
    userVerifiedCount += 1; // Increase count when verified
  } else {
    userVerifiedCount = Math.max(0, userVerifiedCount - 1); // Decrease count but not below 0
  }

  // Update localStorage
  localStorage.setItem("userVerifiedCount", userVerifiedCount);

  console.log("Updated Verified User Count:", userVerifiedCount);
  };

  const editForm = async (formData) => {
    console.log(inputExtraFee, inputDistanceForFlatRate);

    formData.driverExtraFeePerKm = inputExtraFee;
    formData.driverFlatRateDistance = inputDistanceForFlatRate;

    console.log("---Submit form data---", formData);

    try {
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appdriver/editdriver`,
        {
          formData: formData
        }
      );
      if (result.data.status == 200) {
        openNotification("success", "Success", result.data.message);
      } else {
        openNotification("error", "Failure", result.data.message);
      }
    } catch (err) {
      console.log("error--->", err.message);
      openNotification("error", "ERROR", err.message);
    }
  };

  const addForm = async (formData) => {
    console.log(inputExtraFee, inputDistanceForFlatRate);

    formData.driverExtraFeePerKm = inputExtraFee;
    formData.driverFlatRateDistance = inputDistanceForFlatRate;

    console.log("---Submit form data---", formData);

    try {
      // setFormData(())
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appdriver/signupdriver`,
        {
          formData: formData
        }
      );
      if (result.data.status == 200) {
        openNotification("success", "Success", result.data.message);
      } else {
        openNotification("error", "Failure", result.data.message);
      }
    } catch (err) {
      console.log("error--->", err.message);
      openNotification("error", "ERROR", err.message);
    }
  };


  const handleFormSubmit = async () => {
    firstLoad = false;
    console.log("inside the form submit--->", formData);
  
    let validation = true;
    for (let key in formData) {
      // Skip validation for address-related fields
      if (
        ["houseNumber", "street", "city", "state", "pinCode", "country"].includes(key)
      ) {
        continue;
      }
  
      // // Check for other fields
      // if (formData[key] === "") {
      //   console.log("formData key---->", formData[key]);
      //   setErrorObj((prevState) => ({
      //     ...prevState,
      //     [key]: true
      //   }));
      //   validation = false;
      // } else {
      //   setErrorObj((prevState) => ({
      //     ...prevState,
      //     [key]: false
      //   }));
      // }
    }
  
    console.log("validation----->", validation);
    console.log("error obj----->", errorObj);
    console.log("formData======>", formData);
  
    if (validation && props.edit) {
      console.log("validation inside if----->", validation);
      editForm(formData);
    } else if (validation && props.add) {
      console.log("Inside add form Validation=====>", validation);
      addForm(formData);
    }
  };

//   const handleFormSubmit = async () => {
//     firstLoad = false;
//     console.log("inside the form sumbit--->", formData);

//     let validation = true;
//     for (let key in formData) {
//       console.log("key in formData---->", key);
//       if (formData[key] == "") {
//         console.log("formDAta key---->", formData[key]);
//         setErrorObj((prevState) => ({
//           ...prevState,
//           [key]: true
//         }));
//         validation = false;
//       } else {
//         setErrorObj((prevState) => ({
//           ...prevState,
//           [key]: false
//         }));
//       }
//     }
//     console.log("validation----->", validation);
//     console.log("error obj----->", errorObj);
//     console.log("formData======>", formData);
//     if (validation && props.edit) {
//       console.log("validation inside if----->", validation);
//       editForm(formData);
//       // return ;
//     } else if (validation && props.add) {
//       console.log("Inside add form Validation=====>", validation);

//       addForm(formData);
//     }
//   };

  const setExtraFee = (val) => {
    if (!isNaN(val)) {
      //console.log(typeof(val), val, isNaN(val));
      setInputExtraFee(val);
    }
  };

  const setDistanceForFlatRate = (val) => {
    if (!isNaN(val)) {
      setInputDistanceForFlatRate(val);
    }
  };

  return (
    <div className="add-r-main bg-white pb-2 addDriverPage">
      <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
        <Col>
          <h3>{props.edit ? "Edit New Driver" : "Add New Driver"}</h3>
        </Col>
        <Col>
          <Button
            onClick={() => {
              props.handlePageChange("LIST");
            }}
            className="common-btn2"
          >
            Return to the List
          </Button>
        </Col>
      </Row>
      <div className="form-r-main p-3">
        <Form
          {...layout}
          name="nest-messages"
          className="add-r-form p-3"
          onFinish={handleFormSubmit}
        >
          <Row>
            <Col xs={24} lg={12} style={{ padding: "10px" }}>
              <Form.Item name="first-name" label="First Name">
                <Input
                  defaultValue={driverData.firstName || ""}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      firstName: e.target.value
                    }));
                  }}
                />
                {errorObj.firstName ? (
                  <p style={{ color: "red" }}>Please Enter First Name</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} style={{ padding: "10px" }}>
              <Form.Item name="last-name" label="Last Name">
                <Input
                  defaultValue={driverData.lastName || ""}
                  onChange={(e) => {
                    console.log("inside the last Name---->");
                    setFormData((prevState) => ({
                      ...prevState,
                      lastName: e.target.value
                    }));
                  }}
                />
                {errorObj.lastName ? (
                  <p style={{ color: "red" }}>Please Enter The Last Name</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} style={{ padding: "10px" }}>
              <Form.Item name="email" label="E-mail">
                <Input
                  defaultValue={driverData.email || ""}
                  onChange={(e) => {
                    console.log("inside the email----->");
                    setFormData((prevState) => ({
                      ...prevState,
                      email: e.target.value
                    }));
                  }}
                />
                {errorObj.email ? (
                  <p style={{ color: "red" }}>Please Enter Email</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} style={{ padding: "10px" }}>
              <Form.Item name="phone" label="Contact Number">
                <Input
                  defaultValue={driverData.phoneNumber || ""}
                  onChange={(e) => {
                    console.log("phoneNumber----->");
                    setFormData((prevState) => ({
                      ...prevState,
                      phoneNumber: e.target.value
                    }));
                  }}
                />
                {errorObj.phoneNumber ? (
                  <p style={{ color: "red" }}>Please Enter Phone Number</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} style={{ padding: "10px" }}>
              <Form.Item name="houseNumber" label="Address House No.">
                <Input.TextArea
                  defaultValue={driverData?.address?.houseNumber || ""}
                  onChange={(e) => {
                    console.log("inside address1---->", e.target.value);
                    setFormData((prevState) => ({
                      ...prevState,
                      houseNumber: e.target.value
                    }));
                  }}
                />
                {errorObj.houseNumber ? (
                  <p style={{ color: "red" }}>Please Enter House Number</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} style={{ padding: "10px" }}>
              <Form.Item name="streetName" label="Address Street">
                <Input.TextArea
                  defaultValue={driverData?.address?.street || ""}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      street: e.target.value
                    }));
                  }}
                />
                {errorObj.street ? (
                  <p style={{ color: "red" }}>Please Enter Street</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} style={{ padding: "10px" }}>
              <Form.Item name="city" label="City">
                <Input
                  defaultValue={driverData?.address?.city || ""}
                  onChange={(e) => {
                    console.log(
                      "inside the city formItem---->",
                      e.target.value
                    );
                    setFormData((prevState) => ({
                      ...prevState,
                      city: e.target.value
                    }));
                  }}
                />
                {errorObj.city ? (
                  <p style={{ color: "red" }}>Please Enter City</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} style={{ padding: "10px" }}>
              <Form.Item label="State" name="state">
                <Input
                  defaultValue={driverData?.address?.state || ""}
                  onChange={(e) => {
                    console.log(
                      "inside the state formItem---->",
                      e.target.value
                    );
                    setFormData((prevState) => ({
                      ...prevState,
                      state: e.target.value
                    }));
                  }}
                />
                {errorObj.state ? (
                  <p style={{ color: "red" }}>Please Enter State</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} style={{ padding: "10px" }}>
              <Form.Item name="pin" label="PIN">
                <Input
                  defaultValue={driverData?.address?.pinCode || ""}
                  onChange={(e) => {
                    console.log("inside the pin formItem---->", e.target.value);
                    setFormData((prevState) => ({
                      ...prevState,
                      pinCode: e.target.value
                    }));
                  }}
                />
                {errorObj.pinCode ? (
                  <p style={{ color: "red" }}>Please Enter Pin Code</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} style={{ padding: "10px" }}>
              <Form.Item name="country" label="Country">
                <Input
                  defaultValue={driverData?.address?.country || ""}
                  onChange={(e) => {
                    console.log(
                      "inside the country formItem---->",
                      e.target.value
                    );
                    setFormData((prevState) => ({
                      ...prevState,
                      country: e.target.value
                    }));
                  }}
                />
                {errorObj.country ? (
                  <p style={{ color: "red" }}>Please Enter Country</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} style={{ padding: "10px" }}>
              <Form.Item name="vehicletype" label="Vehicle Type">
                <Input
                  defaultValue={driverData?.vehicle?.vehicleType || "notKnown"}
                  onChange={(e) => {
                    console.log(
                      "inside the state formItem---->",
                      e.target.value
                    );
                    setFormData((prevState) => ({
                      ...prevState,
                      vehicleType: e.target.value
                    }));
                  }}
                />
                {errorObj.vehicleType ? (
                  <p style={{ color: "red" }}>Please Enter vehicle Type</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} style={{ padding: "10px" }}>
              <Form.Item name="vehicleno" label="Vehicle No.">
                <Input
                  defaultValue={driverData?.vehicle?.vehicleRegNumber || "0"}
                  onChange={(e) => {
                    console.log(
                      "inside the vehicle formItem---->",
                      e.target.value
                    );
                    setFormData((prevState) => ({
                      ...prevState,
                      vehicleRegNumber: e.target.value
                    }));
                  }}
                />
                {errorObj.vehicleRegNumber ? (
                  <p style={{ color: "red" }}>
                    Please Enter vehicle Registration Number
                  </p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} style={{ padding: "10px" }}>
              <Form.Item name="licenceno" label="License No">
                <Input
                  defaultValue={driverData?.licenseNumber || "0"}
                  onChange={(e) => {
                    console.log(
                      "inside the license formItem---->",
                      e.target.value
                    );
                    setFormData((prevState) => ({
                      ...prevState,
                      licenseNumber: e.target.value
                    }));
                  }}
                />
                {errorObj.licenseNumber ? (
                  <p style={{ color: "red" }}>Please Enter licenseNumber</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} style={{ padding: "10px" }}>
              <Form.Item name="vehiclecolor" label="Vehicle Color">
                <Input
                  defaultValue={driverData?.vehicle?.vehicleColor || "notKnown"}
                  onChange={(e) => {
                    console.log(
                      "inside the vehicle color formItem---->",
                      e.target.value
                    );
                    setFormData((prevState) => ({
                      ...prevState,
                      vehicleColor: e.target.value
                    }));
                  }}
                />
                {errorObj.vehicleColor ? (
                  <p style={{ color: "red" }}>Please Enter vehicle color</p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={8} style={{ padding: "10px" }}>
              <Form.Item name="driverFee" label="Driver Fee">
                <Input
                  defaultValue={driverData?.driverFee?.perDel || 0}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      driverFeePerDel: e.target.value
                    }));
                  }}
                />
                {errorObj.driverFeePerDel ? (
                  <p style={{ color: "red" }}>
                    Please Enter Driver Fees For Each Delivery.
                  </p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={8} style={{ padding: "10px" }}>
              <Form.Item name="inputExtraFeePerKm" label="Extra Fee Per Km">
                <Input
                  defaultValue={inputExtraFee}
                  value={inputExtraFee}
                  onChange={(e) => {
                    setExtraFee(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8} style={{ padding: "10px" }}>
              <Form.Item
                name="inputDistanceForFlatRate"
                label="Dist For Flat Rate"
              >
                <Input
                  defaultValue={inputDistanceForFlatRate}
                  value={inputDistanceForFlatRate}
                  onChange={(e) => {
                    setDistanceForFlatRate(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={24} style={{ padding: "10px" }}>
              {props.add ? (
                <Form.Item name="password" label="Password">
                  <Input
                    onChange={(e) => {
                      console.log("password=====>", formData.password);
                      setFormData((prevState) => ({
                        ...prevState,
                        password: e.target.value
                      }));
                    }}
                  />
                  {errorObj.password ? (
                    <p style={{ color: "red" }}>Please Enter password.</p>
                  ) : (
                    ""
                  )}
                </Form.Item>
              ) : (
                ""
              )}
            </Col>
            <Col xs={24} lg={12} style={{ padding: "10px" }}></Col>
          </Row>

          {props.edit && (
            <>
              <Row>
                <Col className="col-4">
                  <Form.Item name="range1Min" label="Range 1 Min ">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.range1Min || "0"}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          range1Min: e.target.value
                        }));
                      }}
                    />
                    {errorObj.range1Min ? (
                      <p style={{ color: "red" }}>
                        Please Enter Minium value 1 for driver fees per km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
                <Col className="col-4">
                  <Form.Item name="range1Max" label="Range 1 Max">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.range1Max || "0"}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          range1Max: e.target.value
                        }));
                      }}
                    />
                    {errorObj.range1Max ? (
                      <p style={{ color: "red" }}>
                        Please Enter Maximum 1 Value for driver Per Km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
                <Col className="col-4">
                  <Form.Item name="perKmFee1" label="Driver Fees / Km">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.perKmFee1 || "0"}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          perKmFee1: e.target.value
                        }));
                      }}
                    />
                    {errorObj.perKmFee1 ? (
                      <p style={{ color: "red" }}>
                        Please Enter Driver Fees Per Km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col className="col-4">
                  <Form.Item name="range2Min" label="Range 2 Min ">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.range2Min || "0"}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          range2Min: e.target.value
                        }));
                      }}
                    />
                    {errorObj.range2Min ? (
                      <p style={{ color: "red" }}>
                        Please Enter Minium value 2 for driver fees per km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
                <Col className="col-4">
                  <Form.Item name="range2Max" label="Range 2 Max">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.range2Max || "0"}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          range2Max: e.target.value
                        }));
                      }}
                    />
                    {errorObj.range2Max ? (
                      <p style={{ color: "red" }}>
                        Please Enter Maximum 2 Value for driver Per Km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
                <Col className="col-4">
                  <Form.Item name="perKmFee2" label="Driver Fees / Km">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.perKmFee2 || "0"}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          perKmFee2: e.target.value
                        }));
                      }}
                    />
                    {errorObj.perKmFee1 ? (
                      <p style={{ color: "red" }}>
                        Please Enter Driver Fees Per Km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {props.edit && (
            <>
              <Row>
                <Col className="col-4">
                  <Form.Item name="range3Min" label="Range 3 Min ">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.range3Min || "0"}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          range3Min: e.target.value
                        }));
                      }}
                    />
                    {errorObj.range3Min ? (
                      <p style={{ color: "red" }}>
                        Please Enter Minium value 3 for driver fees per km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
                <Col className="col-4">
                  <Form.Item name="range3Max" label="Range 3 Max">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.range3Max || "0"}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          range3Max: e.target.value
                        }));
                      }}
                    />
                    {errorObj.range3Max ? (
                      <p style={{ color: "red" }}>
                        Please Enter Maximum 3 Value for driver Per Km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
                <Col className="col-4">
                  <Form.Item name="perKmFee3" label="Driver Fees / Km">
                    <Input
                      defaultValue={driverData?.driverFeeRange?.perKmFee3 || "0"}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          perKmFee3: e.target.value
                        }));
                      }}
                    />
                    {errorObj.perKmFee3 ? (
                      <p style={{ color: "red" }}>
                        Please Enter Driver Fees Per Km.
                      </p>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Form.Item label="Description">
              <Input.TextArea
                defaultValue={driverData?.description || ""}
                onChange={(e) => {
                  console.log(
                    "inside the description formItem---->",
                    e.target.value
                  );
                  setFormData((prevState) => ({
                    ...prevState,
                    description: e.target.value
                  }));
                }}
              />
            </Form.Item>
          </Row>

          <div className="vehicle-docs-section justify-content-between add-r-head align-items-center px-4 py-3">
  <h3>Driver Documents</h3>

  {driverData?.vehicleDocuments || driverData?.driverDocuments ? (
    <Row gutter={24} className="justify-content-between align-items-center px-4 py-3">
      {[
        { label: "Vehicle Insurance", key: "vehicleInsurance", source: driverData?.vehicleDocuments?.vehicleInsurance },
        { label: "Vehicle Permit", key: "vehiclePermit", source: driverData?.vehicleDocuments?.vehiclePermit },
        { label: "Vehicle RC", key: "vehicleRC", source: driverData?.vehicleDocuments?.vehicleRC },
        { label: "Driver Licence", key: "driverLicence", source: driverData?.driverDocuments?.driverLicence }
      ].map((doc, index) => (
        <Col span={6} key={index}>
          <div className="doc-item">
            <h4>{doc.label}</h4>
            <Avatar
              shape="square"
              size={64}
              src={
                doc.source ? (
                  <Image
                    src={`${s3url}/${doc.source}`}
                    style={{ maxWidth: 300 }}
                  />
                ) : null
              }
            >
              {!doc.source && "Not Uploaded"}
            </Avatar>
          </div>
        </Col>
      ))}
    </Row>
  ) : (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
  <p style={{ color: "red" }}>No documents uploaded.</p>
</div>
  )}
</div>

          {props.edit ? (
            <>
              <Row>
                <Col className="col-12 justify-content-evenly flex pt-6">
                  <Switch
                    checkedChildren="Vehicle"
                    unCheckedChildren="Vehicle"
                    defaultChecked
                  />
                  <Switch
                    checkedChildren="VERIFIED"
                    unCheckedChildren="UNVERIFIED"
                    onChange={(checked) => {
                      verifyDriver(checked, props.driver._id);
                    }}
                    checked={verifiedDriver}
                  />
                  <Switch
                    className="block-check"
                    onChange={(checked) => {
                      onBlockChange(checked, props.driver._id);
                    }}
                    checkedChildren="Blocked"
                    unCheckedChildren="Un-blocked"
                    checked={blockDriver}
                  />
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}

          <Row className=" justify-content-center pt-5">
            <Button type="primary" htmlType="submit">
              {props.edit ? "Save" : "ADD"}
            </Button>
          </Row>
        </Form>
      </div>
    </div>
  );
}

// import React, { useEffect, useState } from "react";
// import { Row, Col, Input, Button, message, Form, Switch,Image,
//   Avatar, } from "antd";
// import api from "../../commonFuncs/api";
// import openNotification from "../../commonFuncs/notification";
// const s3url = "https://pos-stockholm-bucket.s3.eu-north-1.amazonaws.com";

// const propsupload = {
//   name: "file",
//   action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
//   headers: {
//     authorization: "authorization-text"
//   },

//   onChange(info) {
//     if (info.file.status !== "uploading") {
//       console.log(info.file, info.fileList);
//     }

//     if (info.file.status === "done") {
//       message.success(`${info.file.name} file uploaded successfully`);
//     } else if (info.file.status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   }
// };

// const layout = {
//   labelCol: {
//     span: 8
//   },
//   wrapperCol: {
//     span: 16
//   }
// };

// export default function EditDrivers(props) {
//   const [blockDriver, setBlockDriver] = useState(
//     props.driver != undefined ? props.driver.isBlocked : true
//   );
//   const [verifiedDriver, setVerifiedDriver] = useState(
//     props.driver != undefined ? props.driver.isDriverVerified : false
//   );
//   const [driverData, setDriverData] = useState(props.driver || {});

//   const [inputExtraFee, setInputExtraFee] = useState(
//     props.driver && props.driver.driverFee.extraFeePerKm
//       ? props.driver.driverFee.extraFeePerKm
//       : 0
//   );
//   const [inputDistanceForFlatRate, setInputDistanceForFlatRate] = useState(
//     props.driver && props.driver.driverFee.flatRateDistance
//       ? props.driver.driverFee.flatRateDistance
//       : 0
//   );

//   const [errorObj, setErrorObj] = useState({});
//   const [formData, setFormData] = useState({});

//   const onFinish = (values) => {
//     console.log(values);
//   };

//   useEffect(() => {
//     let formValues = {
//       firstName: driverData.firstName || "",
//       lastName: driverData.lastName || "",
//       phoneNumber: driverData.phoneNumber || "",
//       email: driverData.email || "",
//       licenseNumber: driverData.licenseNumber || "0",
//       houseNumber: driverData?.address?.houseNumber || "",
//       street: driverData?.address?.street || "",
//       pinCode: driverData?.address?.pinCode || "",
//       city: driverData?.address?.city || "",
//       state: driverData?.address?.state || "",
//       country: driverData?.address?.country || "",
//       vehicleType: driverData?.vehicle?.vehicleType || "notKnown",
//       vehicleRegNumber: driverData?.vehicle?.vehicleRegNumber || "0",
//       vehicleColor: driverData?.vehicle?.vehicleColor || "notKnown",
//       description: driverData.description || ""
//     };

//     let errorValues = {
//       firstName: false,
//       lastName: false,
//       phoneNumber: false,
//       email: false,
//       licenseNumber: false,
//       houseNumber: false,
//       street: false,
//       pinCode: false,
//       city: false,
//       state: false,
//       country: false,
//       vehicleType: false,
//       vehicleRegNumber: false,
//       vehicleColor: false,
//       description: false
//     };

//     if (props.edit) {
//       //in formValues driver id is added
//       formValues.driverId = driverData._id;
//       formValues.driverFeePerDel = driverData?.driverFee?.perDel || "0";
//       formValues.range1Min = driverData?.driverFeeRange?.range1Min || "0";
//       formValues.range1Max = driverData?.driverFeeRange?.range1Max || "0";
//       formValues.perKmFee1 = driverData?.driverFeeRange?.perKmFee1 || "0";
//       formValues.range2Min = driverData?.driverFeeRange?.range2Min || "0";
//       formValues.range2Max = driverData?.driverFeeRange?.range2Max || "0";
//       formValues.perKmFee2 = driverData?.driverFeeRange?.perKmFee2 || "0";
//       formValues.range3Min = driverData?.driverFeeRange?.range3Min || "0";
//       formValues.range3Max = driverData?.driverFeeRange?.range3Max || "0";
//       formValues.perKmFee3 = driverData?.driverFeeRange?.perKmFee3 || "0";
//       errorValues.range1Min = false;
//       errorValues.range1Max = false;
//       errorValues.perKmFee1 = false;
//       errorValues.range2Min = false;
//       errorValues.range2Max = false;
//       errorValues.perKmFee2 = false;
//       errorValues.range3Min = false;
//       errorValues.range3Max = false;
//       errorValues.perKmFee3 = false;
//       errorValues.driverFeePerDel = false;
//     }
//     if (props.add) {
//       formValues.password = "";
//       errorValues.password = false;
//     }

//     setErrorObj(errorValues);
//     setFormData(formValues);
//   }, []);

//   let firstLoad = true;

//   const onBlockChange = (checked) => {
//     setBlockDriver(checked);
//     props.onBlockStatusChange(checked, props.driver._id);
//   };

//   const verifyDriver = (checked) => {
//     setVerifiedDriver(checked);
//     props.onVerifiedDriver(checked, props.driver._id);
//   };

//   const editForm = async (formData) => {
//     console.log(inputExtraFee, inputDistanceForFlatRate);

//     formData.driverExtraFeePerKm = inputExtraFee;
//     formData.driverFlatRateDistance = inputDistanceForFlatRate;

//     console.log("---Submit form data---", formData);

//     try {
//       let result = await api.post(
//         `/api/v1/treeDriveAdmin/appdriver/editdriver`,
//         {
//           formData: formData
//         }
//       );
//       if (result.data.status == 200) {
//         openNotification("success", "Success", result.data.message);
//       } else {
//         openNotification("error", "Failure", result.data.message);
//       }
//     } catch (err) {
//       console.log("error--->", err.message);
//       openNotification("error", "ERROR", err.message);
//     }
//   };

//   const addForm = async (formData) => {
//     console.log(inputExtraFee, inputDistanceForFlatRate);

//     formData.driverExtraFeePerKm = inputExtraFee;
//     formData.driverFlatRateDistance = inputDistanceForFlatRate;

//     console.log("---Submit form data---", formData);

//     try {
//       // setFormData(())
//       let result = await api.post(
//         `/api/v1/treeDriveAdmin/appdriver/signupdriver`,
//         {
//           formData: formData
//         }
//       );
//       if (result.data.status == 200) {
//         openNotification("success", "Success", result.data.message);
//       } else {
//         openNotification("error", "Failure", result.data.message);
//       }
//     } catch (err) {
//       console.log("error--->", err.message);
//       openNotification("error", "ERROR", err.message);
//     }
//   };


//   const handleFormSubmit = async () => {
//     firstLoad = false;
//     console.log("inside the form submit--->", formData);
  
//     let validation = true;
//     for (let key in formData) {
//       // Skip validation for address-related fields
//       if (
//         ["houseNumber", "street", "city", "state", "pinCode", "country"].includes(key)
//       ) {
//         continue;
//       }
  
//       // Check for other fields
//       if (formData[key] === "") {
//         console.log("formData key---->", formData[key]);
//         setErrorObj((prevState) => ({
//           ...prevState,
//           [key]: true
//         }));
//         validation = false;
//       } else {
//         setErrorObj((prevState) => ({
//           ...prevState,
//           [key]: false
//         }));
//       }
//     }
  
//     console.log("validation----->", validation);
//     console.log("error obj----->", errorObj);
//     console.log("formData======>", formData);
  
//     if (validation && props.edit) {
//       console.log("validation inside if----->", validation);
//       editForm(formData);
//     } else if (validation && props.add) {
//       console.log("Inside add form Validation=====>", validation);
//       addForm(formData);
//     }
//   };

// //   const handleFormSubmit = async () => {
// //     firstLoad = false;
// //     console.log("inside the form sumbit--->", formData);

// //     let validation = true;
// //     for (let key in formData) {
// //       console.log("key in formData---->", key);
// //       if (formData[key] == "") {
// //         console.log("formDAta key---->", formData[key]);
// //         setErrorObj((prevState) => ({
// //           ...prevState,
// //           [key]: true
// //         }));
// //         validation = false;
// //       } else {
// //         setErrorObj((prevState) => ({
// //           ...prevState,
// //           [key]: false
// //         }));
// //       }
// //     }
// //     console.log("validation----->", validation);
// //     console.log("error obj----->", errorObj);
// //     console.log("formData======>", formData);
// //     if (validation && props.edit) {
// //       console.log("validation inside if----->", validation);
// //       editForm(formData);
// //       // return ;
// //     } else if (validation && props.add) {
// //       console.log("Inside add form Validation=====>", validation);

// //       addForm(formData);
// //     }
// //   };

//   const setExtraFee = (val) => {
//     if (!isNaN(val)) {
//       //console.log(typeof(val), val, isNaN(val));
//       setInputExtraFee(val);
//     }
//   };

//   const setDistanceForFlatRate = (val) => {
//     if (!isNaN(val)) {
//       setInputDistanceForFlatRate(val);
//     }
//   };

//   return (
//     <div className="add-r-main bg-white pb-2 addDriverPage">
//       <Row className="justify-content-between align-items-center add-r-head px-4 py-3">
//         <Col>
//           <h3>{props.edit ? "Edit New Driver" : "Add New Driver"}</h3>
//         </Col>
//         <Col>
//           <Button
//             onClick={() => {
//               props.handlePageChange("LIST");
//             }}
//             className="common-btn2"
//           >
//             Return to the List
//           </Button>
//         </Col>
//       </Row>
//       <div className="form-r-main p-3">
//         <Form
//           {...layout}
//           name="nest-messages"
//           className="add-r-form p-3"
//           onFinish={handleFormSubmit}
//         >
//           <Row>
//             <Col xs={24} lg={12} style={{ padding: "10px" }}>
//               <Form.Item name="first-name" label="First Name">
//                 <Input
//                   defaultValue={driverData.firstName || ""}
//                   onChange={(e) => {
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       firstName: e.target.value
//                     }));
//                   }}
//                 />
//                 {errorObj.firstName ? (
//                   <p style={{ color: "red" }}>Please Enter First Name</p>
//                 ) : (
//                   ""
//                 )}
//               </Form.Item>
//             </Col>
//             <Col xs={24} lg={12} style={{ padding: "10px" }}>
//               <Form.Item name="last-name" label="Last Name">
//                 <Input
//                   defaultValue={driverData.lastName || ""}
//                   onChange={(e) => {
//                     console.log("inside the last Name---->");
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       lastName: e.target.value
//                     }));
//                   }}
//                 />
//                 {errorObj.lastName ? (
//                   <p style={{ color: "red" }}>Please Enter The Last Name</p>
//                 ) : (
//                   ""
//                 )}
//               </Form.Item>
//             </Col>
//             <Col xs={24} lg={12} style={{ padding: "10px" }}>
//               <Form.Item name="email" label="E-mail">
//                 <Input
//                   defaultValue={driverData.email || ""}
//                   onChange={(e) => {
//                     console.log("inside the email----->");
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       email: e.target.value
//                     }));
//                   }}
//                 />
//                 {errorObj.email ? (
//                   <p style={{ color: "red" }}>Please Enter Email</p>
//                 ) : (
//                   ""
//                 )}
//               </Form.Item>
//             </Col>
//             <Col xs={24} lg={12} style={{ padding: "10px" }}>
//               <Form.Item name="phone" label="Contact Number">
//                 <Input
//                   defaultValue={driverData.phoneNumber || ""}
//                   onChange={(e) => {
//                     console.log("phoneNumber----->");
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       phoneNumber: e.target.value
//                     }));
//                   }}
//                 />
//                 {errorObj.phoneNumber ? (
//                   <p style={{ color: "red" }}>Please Enter Phone Number</p>
//                 ) : (
//                   ""
//                 )}
//               </Form.Item>
//             </Col>
//             <Col xs={24} lg={12} style={{ padding: "10px" }}>
//               <Form.Item name="houseNumber" label="Address House No.">
//                 <Input.TextArea
//                   defaultValue={driverData?.address?.houseNumber || ""}
//                   onChange={(e) => {
//                     console.log("inside address1---->", e.target.value);
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       houseNumber: e.target.value
//                     }));
//                   }}
//                 />
//                 {errorObj.houseNumber ? (
//                   <p style={{ color: "red" }}>Please Enter House Number</p>
//                 ) : (
//                   ""
//                 )}
//               </Form.Item>
//             </Col>
//             <Col xs={24} lg={12} style={{ padding: "10px" }}>
//               <Form.Item name="streetName" label="Address Street">
//                 <Input.TextArea
//                   defaultValue={driverData?.address?.street || ""}
//                   onChange={(e) => {
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       street: e.target.value
//                     }));
//                   }}
//                 />
//                 {errorObj.street ? (
//                   <p style={{ color: "red" }}>Please Enter Street</p>
//                 ) : (
//                   ""
//                 )}
//               </Form.Item>
//             </Col>
//             <Col xs={24} lg={6} style={{ padding: "10px" }}>
//               <Form.Item name="city" label="City">
//                 <Input
//                   defaultValue={driverData?.address?.city || ""}
//                   onChange={(e) => {
//                     console.log(
//                       "inside the city formItem---->",
//                       e.target.value
//                     );
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       city: e.target.value
//                     }));
//                   }}
//                 />
//                 {errorObj.city ? (
//                   <p style={{ color: "red" }}>Please Enter City</p>
//                 ) : (
//                   ""
//                 )}
//               </Form.Item>
//             </Col>
//             <Col xs={24} lg={6} style={{ padding: "10px" }}>
//               <Form.Item label="State" name="state">
//                 <Input
//                   defaultValue={driverData?.address?.state || ""}
//                   onChange={(e) => {
//                     console.log(
//                       "inside the state formItem---->",
//                       e.target.value
//                     );
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       state: e.target.value
//                     }));
//                   }}
//                 />
//                 {errorObj.state ? (
//                   <p style={{ color: "red" }}>Please Enter State</p>
//                 ) : (
//                   ""
//                 )}
//               </Form.Item>
//             </Col>
//             <Col xs={24} lg={6} style={{ padding: "10px" }}>
//               <Form.Item name="pin" label="PIN">
//                 <Input
//                   defaultValue={driverData?.address?.pinCode || ""}
//                   onChange={(e) => {
//                     console.log("inside the pin formItem---->", e.target.value);
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       pinCode: e.target.value
//                     }));
//                   }}
//                 />
//                 {errorObj.pinCode ? (
//                   <p style={{ color: "red" }}>Please Enter Pin Code</p>
//                 ) : (
//                   ""
//                 )}
//               </Form.Item>
//             </Col>
//             <Col xs={24} lg={6} style={{ padding: "10px" }}>
//               <Form.Item name="country" label="Country">
//                 <Input
//                   defaultValue={driverData?.address?.country || ""}
//                   onChange={(e) => {
//                     console.log(
//                       "inside the country formItem---->",
//                       e.target.value
//                     );
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       country: e.target.value
//                     }));
//                   }}
//                 />
//                 {errorObj.country ? (
//                   <p style={{ color: "red" }}>Please Enter Country</p>
//                 ) : (
//                   ""
//                 )}
//               </Form.Item>
//             </Col>
//             <Col xs={24} lg={6} style={{ padding: "10px" }}>
//               <Form.Item name="vehicletype" label="Vehicle Type">
//                 <Input
//                   defaultValue={driverData?.vehicle?.vehicleType || "notKnown"}
//                   onChange={(e) => {
//                     console.log(
//                       "inside the state formItem---->",
//                       e.target.value
//                     );
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       vehicleType: e.target.value
//                     }));
//                   }}
//                 />
//                 {errorObj.vehicleType ? (
//                   <p style={{ color: "red" }}>Please Enter vehicle Type</p>
//                 ) : (
//                   ""
//                 )}
//               </Form.Item>
//             </Col>
//             <Col xs={24} lg={6} style={{ padding: "10px" }}>
//               <Form.Item name="vehicleno" label="Vehicle No.">
//                 <Input
//                   defaultValue={driverData?.vehicle?.vehicleRegNumber || "0"}
//                   onChange={(e) => {
//                     console.log(
//                       "inside the vehicle formItem---->",
//                       e.target.value
//                     );
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       vehicleRegNumber: e.target.value
//                     }));
//                   }}
//                 />
//                 {errorObj.vehicleRegNumber ? (
//                   <p style={{ color: "red" }}>
//                     Please Enter vehicle Registration Number
//                   </p>
//                 ) : (
//                   ""
//                 )}
//               </Form.Item>
//             </Col>
//             <Col xs={24} lg={6} style={{ padding: "10px" }}>
//               <Form.Item name="licenceno" label="License No">
//                 <Input
//                   defaultValue={driverData?.licenseNumber || "0"}
//                   onChange={(e) => {
//                     console.log(
//                       "inside the license formItem---->",
//                       e.target.value
//                     );
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       licenseNumber: e.target.value
//                     }));
//                   }}
//                 />
//                 {errorObj.licenseNumber ? (
//                   <p style={{ color: "red" }}>Please Enter licenseNumber</p>
//                 ) : (
//                   ""
//                 )}
//               </Form.Item>
//             </Col>
//             <Col xs={24} lg={6} style={{ padding: "10px" }}>
//               <Form.Item name="vehiclecolor" label="Vehicle Color">
//                 <Input
//                   defaultValue={driverData?.vehicle?.vehicleColor || "notKnown"}
//                   onChange={(e) => {
//                     console.log(
//                       "inside the vehicle color formItem---->",
//                       e.target.value
//                     );
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       vehicleColor: e.target.value
//                     }));
//                   }}
//                 />
//                 {errorObj.vehicleColor ? (
//                   <p style={{ color: "red" }}>Please Enter vehicle color</p>
//                 ) : (
//                   ""
//                 )}
//               </Form.Item>
//             </Col>
//             <Col xs={24} lg={8} style={{ padding: "10px" }}>
//               <Form.Item name="driverFee" label="Driver Fee">
//                 <Input
//                   defaultValue={driverData?.driverFee?.perDel || 0}
//                   onChange={(e) => {
//                     setFormData((prevState) => ({
//                       ...prevState,
//                       driverFeePerDel: e.target.value
//                     }));
//                   }}
//                 />
//                 {errorObj.driverFeePerDel ? (
//                   <p style={{ color: "red" }}>
//                     Please Enter Driver Fees For Each Delivery.
//                   </p>
//                 ) : (
//                   ""
//                 )}
//               </Form.Item>
//             </Col>
//             <Col xs={24} lg={8} style={{ padding: "10px" }}>
//               <Form.Item name="inputExtraFeePerKm" label="Extra Fee Per Km">
//                 <Input
//                   defaultValue={inputExtraFee}
//                   value={inputExtraFee}
//                   onChange={(e) => {
//                     setExtraFee(e.target.value);
//                   }}
//                 />
//               </Form.Item>
//             </Col>
//             <Col xs={24} lg={8} style={{ padding: "10px" }}>
//               <Form.Item
//                 name="inputDistanceForFlatRate"
//                 label="Dist For Flat Rate"
//               >
//                 <Input
//                   defaultValue={inputDistanceForFlatRate}
//                   value={inputDistanceForFlatRate}
//                   onChange={(e) => {
//                     setDistanceForFlatRate(e.target.value);
//                   }}
//                 />
//               </Form.Item>
//             </Col>
//             <Col xs={24} lg={24} style={{ padding: "10px" }}>
//               {props.add ? (
//                 <Form.Item name="password" label="Password">
//                   <Input
//                     onChange={(e) => {
//                       console.log("password=====>", formData.password);
//                       setFormData((prevState) => ({
//                         ...prevState,
//                         password: e.target.value
//                       }));
//                     }}
//                   />
//                   {errorObj.password ? (
//                     <p style={{ color: "red" }}>Please Enter password.</p>
//                   ) : (
//                     ""
//                   )}
//                 </Form.Item>
//               ) : (
//                 ""
//               )}
//             </Col>
//             <Col xs={24} lg={12} style={{ padding: "10px" }}></Col>
//           </Row>

//           {props.edit && (
//             <>
//               <Row>
//                 <Col className="col-4">
//                   <Form.Item name="range1Min" label="Range 1 Min ">
//                     <Input
//                       defaultValue={driverData?.driverFeeRange?.range1Min || "0"}
//                       onChange={(e) => {
//                         setFormData((prevState) => ({
//                           ...prevState,
//                           range1Min: e.target.value
//                         }));
//                       }}
//                     />
//                     {errorObj.range1Min ? (
//                       <p style={{ color: "red" }}>
//                         Please Enter Minium value 1 for driver fees per km.
//                       </p>
//                     ) : (
//                       ""
//                     )}
//                   </Form.Item>
//                 </Col>
//                 <Col className="col-4">
//                   <Form.Item name="range1Max" label="Range 1 Max">
//                     <Input
//                       defaultValue={driverData?.driverFeeRange?.range1Max || "0"}
//                       onChange={(e) => {
//                         setFormData((prevState) => ({
//                           ...prevState,
//                           range1Max: e.target.value
//                         }));
//                       }}
//                     />
//                     {errorObj.range1Max ? (
//                       <p style={{ color: "red" }}>
//                         Please Enter Maximum 1 Value for driver Per Km.
//                       </p>
//                     ) : (
//                       ""
//                     )}
//                   </Form.Item>
//                 </Col>
//                 <Col className="col-4">
//                   <Form.Item name="perKmFee1" label="Driver Fees / Km">
//                     <Input
//                       defaultValue={driverData?.driverFeeRange?.perKmFee1 || "0"}
//                       onChange={(e) => {
//                         setFormData((prevState) => ({
//                           ...prevState,
//                           perKmFee1: e.target.value
//                         }));
//                       }}
//                     />
//                     {errorObj.perKmFee1 ? (
//                       <p style={{ color: "red" }}>
//                         Please Enter Driver Fees Per Km.
//                       </p>
//                     ) : (
//                       ""
//                     )}
//                   </Form.Item>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col className="col-4">
//                   <Form.Item name="range2Min" label="Range 2 Min ">
//                     <Input
//                       defaultValue={driverData?.driverFeeRange?.range2Min || "0"}
//                       onChange={(e) => {
//                         setFormData((prevState) => ({
//                           ...prevState,
//                           range2Min: e.target.value
//                         }));
//                       }}
//                     />
//                     {errorObj.range2Min ? (
//                       <p style={{ color: "red" }}>
//                         Please Enter Minium value 2 for driver fees per km.
//                       </p>
//                     ) : (
//                       ""
//                     )}
//                   </Form.Item>
//                 </Col>
//                 <Col className="col-4">
//                   <Form.Item name="range2Max" label="Range 2 Max">
//                     <Input
//                       defaultValue={driverData?.driverFeeRange?.range2Max || "0"}
//                       onChange={(e) => {
//                         setFormData((prevState) => ({
//                           ...prevState,
//                           range2Max: e.target.value
//                         }));
//                       }}
//                     />
//                     {errorObj.range2Max ? (
//                       <p style={{ color: "red" }}>
//                         Please Enter Maximum 2 Value for driver Per Km.
//                       </p>
//                     ) : (
//                       ""
//                     )}
//                   </Form.Item>
//                 </Col>
//                 <Col className="col-4">
//                   <Form.Item name="perKmFee2" label="Driver Fees / Km">
//                     <Input
//                       defaultValue={driverData?.driverFeeRange?.perKmFee2 || "0"}
//                       onChange={(e) => {
//                         setFormData((prevState) => ({
//                           ...prevState,
//                           perKmFee2: e.target.value
//                         }));
//                       }}
//                     />
//                     {errorObj.perKmFee1 ? (
//                       <p style={{ color: "red" }}>
//                         Please Enter Driver Fees Per Km.
//                       </p>
//                     ) : (
//                       ""
//                     )}
//                   </Form.Item>
//                 </Col>
//               </Row>
//             </>
//           )}
//           {props.edit && (
//             <>
//               <Row>
//                 <Col className="col-4">
//                   <Form.Item name="range3Min" label="Range 3 Min ">
//                     <Input
//                       defaultValue={driverData?.driverFeeRange?.range3Min || "0"}
//                       onChange={(e) => {
//                         setFormData((prevState) => ({
//                           ...prevState,
//                           range3Min: e.target.value
//                         }));
//                       }}
//                     />
//                     {errorObj.range3Min ? (
//                       <p style={{ color: "red" }}>
//                         Please Enter Minium value 3 for driver fees per km.
//                       </p>
//                     ) : (
//                       ""
//                     )}
//                   </Form.Item>
//                 </Col>
//                 <Col className="col-4">
//                   <Form.Item name="range3Max" label="Range 3 Max">
//                     <Input
//                       defaultValue={driverData?.driverFeeRange?.range3Max || "0"}
//                       onChange={(e) => {
//                         setFormData((prevState) => ({
//                           ...prevState,
//                           range3Max: e.target.value
//                         }));
//                       }}
//                     />
//                     {errorObj.range3Max ? (
//                       <p style={{ color: "red" }}>
//                         Please Enter Maximum 3 Value for driver Per Km.
//                       </p>
//                     ) : (
//                       ""
//                     )}
//                   </Form.Item>
//                 </Col>
//                 <Col className="col-4">
//                   <Form.Item name="perKmFee3" label="Driver Fees / Km">
//                     <Input
//                       defaultValue={driverData?.driverFeeRange?.perKmFee3 || "0"}
//                       onChange={(e) => {
//                         setFormData((prevState) => ({
//                           ...prevState,
//                           perKmFee3: e.target.value
//                         }));
//                       }}
//                     />
//                     {errorObj.perKmFee3 ? (
//                       <p style={{ color: "red" }}>
//                         Please Enter Driver Fees Per Km.
//                       </p>
//                     ) : (
//                       ""
//                     )}
//                   </Form.Item>
//                 </Col>
//               </Row>
//             </>
//           )}

//           <Row>
//             <Form.Item label="Description">
//               <Input.TextArea
//                 defaultValue={driverData?.description || ""}
//                 onChange={(e) => {
//                   console.log(
//                     "inside the description formItem---->",
//                     e.target.value
//                   );
//                   setFormData((prevState) => ({
//                     ...prevState,
//                     description: e.target.value
//                   }));
//                 }}
//               />
//             </Form.Item>
//           </Row>

//           <div className="vehicle-docs-section justify-content-between add-r-head align-items-center px-4 py-3">
//   <h3>Driver Documents</h3>

//   {driverData?.vehicleDocuments || driverData?.driverDocuments ? (
//     <Row gutter={24} className="justify-content-between align-items-center px-4 py-3">
//       {[
//         { label: "Vehicle Insurance", key: "vehicleInsurance", source: driverData?.vehicleDocuments?.vehicleInsurance },
//         { label: "Vehicle Permit", key: "vehiclePermit", source: driverData?.vehicleDocuments?.vehiclePermit },
//         { label: "Vehicle RC", key: "vehicleRC", source: driverData?.vehicleDocuments?.vehicleRC },
//         { label: "Driver Licence", key: "driverLicence", source: driverData?.driverDocuments?.driverLicence }
//       ].map((doc, index) => (
//         <Col span={6} key={index}>
//           <div className="doc-item">
//             <h4>{doc.label}</h4>
//             <Avatar
//               shape="square"
//               size={64}
//               src={
//                 doc.source ? (
//                   <Image
//                     src={`${s3url}/${doc.source}`}
//                     style={{ maxWidth: 300 }}
//                   />
//                 ) : null
//               }
//             >
//               {!doc.source && "Not Uploaded"}
//             </Avatar>
//           </div>
//         </Col>
//       ))}
//     </Row>
//   ) : (
//     <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//   <p style={{ color: "red" }}>No documents uploaded.</p>
// </div>
//   )}
// </div>

//           {/* <div className="vehicle-docs-section justify-content-between add-r-head align-items-center px-4 py-3">
//           <h3>Driver Documents</h3>
//           {driverData?.vehicleDocuments && (
//     <Row gutter={24} className="justify-content-between align-items-center px-4 py-3">
//       <Col span={6}>
//         <div className="doc-item">
//           <h4>Vehicle Insurance</h4>
//           <Avatar
//             shape="square"
//             size={64}
//             src={
//               driverData.vehicleDocuments?.vehicleInsurance ? (
//                 <Image
//                   src={`${s3url}/${driverData.vehicleDocuments.vehicleInsurance}`}
//                   style={{ maxWidth: 300 }}
//                 />
//               ) : null
//             }
//           >
//             {!driverData.vehicleDocuments?.vehicleInsurance && "Not Uploaded"}
//           </Avatar>
//         </div>
//       </Col>

//       <Col span={6}>
//         <div className="doc-item">
//           <h4>Vehicle Permit</h4>
//           <Avatar
//             shape="square"
//             size={64}
//             src={
//               driverData.vehicleDocuments?.vehiclePermit ? (
//                 <Image
//                   src={`${s3url}/${driverData.vehicleDocuments.vehiclePermit}`}
//                   style={{ maxWidth: 300 }}
//                 />
//               ) : null
//             }
//           >
//             {!driverData.vehicleDocuments?.vehiclePermit && "Not Uploaded"}
//           </Avatar>
//         </div>
//       </Col>

//       <Col span={6}>
//         <div className="doc-item">
//           <h4>Vehicle RC</h4>
//           <Avatar
//             shape="square"
//             size={64}
//             src={
//               driverData.vehicleDocuments?.vehicleRC ? (
//                 <Image
//                   src={`${s3url}/${driverData.vehicleDocuments.vehicleRC}`}
//                   style={{ maxWidth: 300 }}
//                 />
//               ) : null
//             }
//           >
//             {!driverData.vehicleDocuments?.vehicleRC && "Not Uploaded"}
//           </Avatar>
//         </div>
//       </Col>

//       {driverData?.driverDocuments && (
//         <Col span={6}>
//           <div className="doc-item">
//             <h4>Driver Licence</h4>
//             <Avatar
//               shape="square"
//               size={64}
//               src={
//                 driverData.driverDocuments?.driverLicence ? (
//                   <Image
//                     src={`${s3url}/${driverData.driverDocuments.driverLicence}`}
//                     style={{ maxWidth: 300 }}
//                   />
//                 ) : null
//               }
//             >
//               {!driverData.driverDocuments?.driverLicence && "Not Uploaded"}
//             </Avatar>
//           </div>
//         </Col>
//       )}
//     </Row>
  
// )}
// </div> */}

//           {props.edit ? (
//             <>
//               <Row>
//                 <Col className="col-12 justify-content-evenly flex pt-6">
//                   <Switch
//                     checkedChildren="Vehicle"
//                     unCheckedChildren="Vehicle"
//                     defaultChecked
//                   />
//                   <Switch
//                     checkedChildren="VERIFIED"
//                     unCheckedChildren="UNVERIFIED"
//                     onChange={(checked) => {
//                       verifyDriver(checked, props.driver._id);
//                     }}
//                     checked={verifiedDriver}
//                   />
//                   <Switch
//                     className="block-check"
//                     onChange={(checked) => {
//                       onBlockChange(checked, props.driver._id);
//                     }}
//                     checkedChildren="Blocked"
//                     unCheckedChildren="Un-blocked"
//                     checked={blockDriver}
//                   />
//                 </Col>
//               </Row>
//             </>
//           ) : (
//             ""
//           )}

//           <Row className=" justify-content-center pt-5">
//             <Button type="primary" htmlType="submit">
//               {props.edit ? "Save" : "ADD"}
//             </Button>
//           </Row>
//         </Form>
//       </div>
//     </div>
//   );
// }
