import {
  Table,
  Space,
  Row,
  Col,
  Rate,
  Tag,
  Input,
  Button,
  Tooltip
} from "antd";

import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  LeftCircleOutlined
} from "@ant-design/icons";
// import Button from 'react-bootstrap/Button';
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Highlighter from "react-highlight-words";
import api from "./../../commonFuncs/api";
import { fetchDrivers } from "./../../reducers/driversReducer";
import ViewDriver from "./ViewDriver.js";
import AddDriver from "./AddDrivers";
import EditDriver from './EditDrivers.js'
import AllOrders from "./AllOrders";
import PaymentHist from "./PaymentHist";
// import api from "./../../commonFuncs/api";
import openNotification from "./../../commonFuncs/notification";
const mockVal = (str, repeat = 1) => ({
  value: str.repeat(repeat)
});

export default function DriverListMain(props) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  let [driversData, setDriversData] = useState([]);
  const [blockDriver, setBlockDriver] = useState(false);
  const [verifiedDriver, setVerifiedDriver] = useState(false);
  const [singleDriverData, setSingleDriverData] = useState({});
  const [payDriver, setPayDriver] = useState(false);
  // const [checkedSwitch,setCheckedSwitch]=useState(false);
  const [pageStatus, setPageStatus] = useState("LIST");
  const searchInput = useRef(null);
  const dispatch = useDispatch();
  let drivers = useSelector((state) => state.drivers);
  let newDriversData = { ...drivers };

  useEffect(() => {
    fetchDriversApi();
  }, [blockDriver, pageStatus, verifiedDriver, payDriver]);
  const fetchDriversApi = async () => {
    try {
      let result = await api.get(
        `/api/v1/treeDriveAdmin/appdriver/driverfetch`
      );

      setDriversData(result.data.data.drivers);
      dispatch(fetchDrivers(result.data.data.drivers));
    } catch (err) {
      console.log("error--->", err.message);
    }
  };
  console.log("singleDriverDatra====>", singleDriverData);
  const handlePageChange = (status) => {
    setPageStatus(status);
  };
  // const viewAllOrderByDriver=(driverId,status)=>{
  //    setPageStatus(status);
  // }
  const onBlockStatusChange = async (checked, driverId) => {
    try {
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appdriver/blockDriver`,
        {
          driverId: driverId,
          status: checked
        }
      );
      if (result.data.status == 200) {
        openNotification("success", "SUCCESS", result.data.message);
      }
      dispatch(fetchDrivers(result.data.data.drivers));
      setBlockDriver(!blockDriver);
    } catch (err) {
      console.log("error--->", err.message);
      openNotification("error", "ERROR", err.message);
    }
  };
  const onVerifiedDriver = async (checked, driverId) => {
    try {
      let result = await api.post(
        `/api/v1/treeDriveAdmin/appdriver/verifydriver`,
        {
          driverId: driverId,
          status: checked
        }
      );
      if (result.data.status == 200) {
        openNotification("success", "SUCCESS", result.data.message);
      }
      dispatch(fetchDrivers(result.data.data.drivers));
      setVerifiedDriver(!verifiedDriver);
    } catch (err) {
      console.log("error--->", err.message);
      openNotification("error", "ERROR", err.message);
    }
  };

  const changePayDriverStatus = () => {
    console.log("inside changePayDriverStatus=====>");
    setPayDriver(!payDriver);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div
        style={{
          padding: 8
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block"
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      )
  });
  const columns = [
    {
      title: "S.No",
      fixed: "left",
      key: "sno",
      render: (record, item, index) => {
        console.log("indexss--->", item);
        return index + 1;
      }
    },
    {
      title: "Name",
      fixed: "left",
      key: "firstName",
      render: (record, item, index) => {
        return item.firstName + " " + item.lastName;
      }
    },

    // {
    //   title: "Last Name",
    //   key: "lastName",
    //   render: (record, item, index) => {
    //     return item.lastName;
    //   }
    // },

    {
      title: "Phone Number",
      key: "phoneNumber",
      render: (record, item, index) => {
        return item.phoneNumber;
      }
    },
    {
      title: "E-mail",
      key: "email",
      render: (record, item) => {
        return record.email ? record.email : "";
      }
    },

    // {
    //   title: "Contact Details",
    //   key: "contactDetails",
    //   render: (record, item, index) => {
    //     return (
    //       <div>
    //         <p className="mb-0">{record.email ? record.email : ""}</p>
    //         <p className="mb-0">{item.phoneNumber}</p>
    //       </div>
    //     );
    //   }
    // },

    // ...getColumnSearchProps('email'),
    // },
    // {
    //   title: 'Block User',
    //   key: 'block user',
    //   render:(record,item,index)=>{

    //     return (<BootstrapSwitchButton
    //       size="sm"
    //       // style="btn"
    //       onstyle="secondary"
    //       // offstyle="danger"
    //       onlabel="Blocked"
    //       offlabel='Unblocked'
    //       checked={item.isBlocked}  onChange={(checked)=>{
    //       onBlockStatusChange(checked,item._id)
    //     }}

    //    />)
    //   }
    // },
    {
      title: "TOTAL",
      children: [
        {
          title: "Distance Covered (Km)",
          key: "totalDistance",
          render: (record, item, index) => {
            return item.totalDistance.toFixed(2) + " Km";
          }
        },
        {
          title: "Order Delieverd",
          key: "totalDel",
          render: (record, item, index) => {
            return item.totalOrderDelivered;
          }
        },
        {
          title: "Earning",
          key: "totalEarn",
          render: (record, item, index) => {
            return "Kr. " + item?.earning?.totalEarning.toFixed(2);
          }
        }
      ]
    },
    {
      title: "Pending Earning",
      key: "pending",
      render: (record, item, index) => {
        return "Kr. " + item?.earning?.pendingEarning.toFixed(2);
      }
    },

    {
      title: "Location",
      key: "address",
      render: (record, item, index) => {
        if (item.address.location.coordinates[0]) {
          let url = `https://www.google.com/maps/search/?api=1&query=${item?.address?.location.coordinates[1]},${item?.address?.location.coordinates[0]}`;
          return (
            <a
              href={url}
              target="_blank"
              style={{
                color: "#0463EF",
                fontWeight: 600,
                textDecoration: "underline"
              }}
            >
              View
            </a>
          );
        } else {
          return "No Location";
        }
      }
    },

    {
      title: "Verified Driver",
      key: "verify Driver",
      render: (record, item, index) => {
        let color;
        color = item.isDriverVerified ? "green" : "red";
        return <Tag color={color}>{item.isDriverVerified ? "YES" : "NO"}</Tag>;
      }
    },
    {
      title: "Driver Available",
      key: "Available Driver",
      render: (record, item, index) => {
        let color;
        color = item.isDriverAvailable ? "green" : "red";
        return <Tag color={color}>{item.isDriverAvailable ? "YES" : "NO"}</Tag>;
      }
    },
    {
      title: "Action",
      // fixed: "right",
      key: "action",
      render: (_, record) => (
        <Space size="default">
          <Tooltip title="Edit" placement="left" color={"green"}>
            <span>
              <Button
                className="border-0 py-0"
                onClick={() => {
                  setSingleDriverData((prevState) => ({ ...record }));
                  setPageStatus("EDIT");
                }}
              >
                <EditOutlined />
              </Button>
            </span>
          </Tooltip>
          <Tooltip title="View" placement="top" color={"blue"}>
            <span>
              <Button
                className="border-0 px-0"
                onClick={() => {
                  setSingleDriverData((prevState) => ({ ...record }));
                  setPageStatus("VIEW");
                }}
              >
                <EyeOutlined />
              </Button>
            </span>
          </Tooltip>
          {/* <Tooltip title="Delete" placement="bottom" color={"red"}>
            <span>
              <a>
                <DeleteOutlined />
              </a>
            </span>
          </Tooltip> */}
        </Space>
      ),
      width: "8%"
    }
  ];

  const listPage = () => {
    return (
      <>
        <div className="user-list">
          <Row className="justify-content-between py-2">
            <Col>
              <h4>List of Drivers</h4>
            </Col>
            <Col>
              <Button
                className="common-btn2"
                onClick={() => {
                  setPageStatus("ADD");
                }}
              >
                Add Driver
              </Button>
            </Col>
          </Row>
          <Row>
            <Table
              scroll={{ x: true }}
              className="user-table"
              columns={columns}
              dataSource={drivers}
              striped
              bordered
              hover
              pagination={{
                pageSize: 10
              }}
            />
          </Row>
        </div>
      </>
    );
  };
  if (pageStatus === "LIST") {
    return listPage();
  } else if (pageStatus == "VIEW") {
    return (
      <ViewDriver
        changePayDriverStatus={changePayDriverStatus}
        view={true}
        handlePageChange={handlePageChange}
        driver={singleDriverData}
      />
    );
  } else if (pageStatus == "EDIT") {
    return (
      <EditDriver
        edit={true}
        onVerifiedDriver={onVerifiedDriver}
        onBlockStatusChange={onBlockStatusChange}
        handlePageChange={handlePageChange}
        driver={singleDriverData}
      />
    );
  } else if (pageStatus == "ADD") {
    return (
      <AddDriver add={true} edit={false} handlePageChange={handlePageChange} />
    );
  } else if (pageStatus == "ALLORDERS") {
    return (
      <AllOrders
        driver={singleDriverData}
        handlePageChange={handlePageChange}
      />
    );
  } else if (pageStatus == "PAYMENTHISTORY") {
    return (
      <PaymentHist
        driver={singleDriverData}
        handlePageChange={handlePageChange}
      />
    );
  }
}
